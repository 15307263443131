.h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 20px;
  letter-spacing: -0.08rem;
  font-family: 'NotoSansKR';

  @media (max-width: 1023px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.p {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  line-height: 1.7;
  letter-spacing: -0.04rem;
  margin: 0 0 20px;
  font-family: 'NotoSansKR';

  @media (max-width: 1023px) {
    font-size: 12px;
  }
}

@primary-color: #4c72f1;