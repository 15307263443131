.basic_layout {
  & > aside {
    position: fixed;
    background: @primary-color;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 240px;
    min-width: 240px;
    transition: all 200ms ease-in-out;
    overflow: hidden;
    z-index: 10;
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
      width: 100%;
      transform: translateX(-100%);
    }

    .bi {
      position: relative;
      padding: 20px 20px;
      z-index: 10;

      img {
        max-width: 200px;
        width: 160px;
        height: 35px;
        object-fit: contain;
        transition: all 200ms ease-in;
      }

      :global(.ant-btn) {
        display: none;
      }

      @media (max-width: 1023px) {
        width: 100%;
        position: relative;
        padding: 20px 20px;

        :global(.ant-btn) {
          display: block;
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 20px;
        }
      }
    }

    .intro_bg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: url('../assets/bg_login_adv.jpg') no-repeat center;
      opacity: 0;
      transition: opacity 180ms ease-in;
      padding: 0px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 50vw;
    }

    .center_contents {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      opacity: 0;
      transition: opacity 180ms ease-in;
      padding: 0px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      @media (max-width: 1023px) {
        position: relative;
        padding: 30px 20px;
      }
    }

    .aside_body {
      flex: 1;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.4);
      }
      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }
    }
  }
  .auth_body {
    padding-left: 50%;
    position: absolute;
    width: 100%;
    min-height: 100%;
    background: #ffffff;
    opacity: 0;
    transition: opacity 100ms ease-in;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1023px) {
      padding-left: 0px;
      width: 100%;
      min-height: 0%;
    }
  }
}

.basic_layout.side_open > aside {
  @media (max-width: 1023px) {
    transform: translateX(0%);
  }
}

.basic_layout.auth {
  & > aside {
    width: 50%;
    z-index: 10;

    @media (max-width: 1023px) {
      transform: translateX(0%) !important;
      width: 100%;
      position: relative;
    }

    .bi img {
      width: 200px;
      height: 40px;
      object-fit: contain;
      margin-left: 35px;

      @media (max-width: 1023px) {
        max-width: 150px;
        margin-left: 0px;
      }
    }

    .intro_bg {
      opacity: 1;
    }
    .center_contents {
      opacity: 1;
    }
  }
  .auth_body {
    opacity: 1;
    @media (max-width: 1023px) {
      position: relative;
      padding: 50px 0px;
    }
  }
}

.basic_layout .menu {
  position: relative;
  z-index: 1;
  & > :global(.ant-menu) {
    background: transparent;
    border-right: none;
    & > :global(.ant-menu-item) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    & > :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    & > :global(.ant-menu-submenu) > :global(.ant-menu) {
      background: rgba(0, 0, 0, 0.2);
      padding: 5px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  //:global(.ant-menu-item-selected) {
  //  color: #fff;
  //}
  //
  //:global(.ant-menu-submenu-title) {
  //  background: transparent;
  //  color: rgba(255, 255, 255, 0.5);
  //}
}

//.basic_layout .menu > ul {
//  list-style-type: none;
//  padding: 0px 0px 0px 20px;
//  margin: 0px;
//}
//
//.basic_layout .menu > ul > li {
//  border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
//}
//
//.basic_layout .menu > ul > li > a {
//  display: flex;
//  flex-direction: row;
//  padding: 10px 0px;
//  color: #99b1fe;
//}
//
//.basic_layout .menu > ul > li > a:hover {
//  color: #ffffff;
//}
//
//.basic_layout .menu > ul > li > a .icon {
//  display: inline-block;
//  width: 24px;
//  font-size: 12px;
//  transition-duration: 0.2s;
//}
//
//.basic_layout .menu > ul > li > a .label {
//  font-size: 12px;
//  line-height: 20px;
//  font-weight: 500;
//  letter-spacing: -0.05rem;
//  transition-duration: 0.2s;
//}
//
//.basic_layout .menu > ul > li > ul {
//  background: rgba(0, 0, 0, 0.25);
//  list-style-type: none;
//  margin: 0px 0px 0px -20px;
//  padding: 5px 0px 5px 44px;
//}
//
//.basic_layout .menu > ul > li > ul > li a {
//  display: block;
//  font-size: 12px;
//  line-height: 35px;
//  color: rgba(255, 255, 255, 0.4);
//}

.body {
  margin-left: 240px;
  padding: 80px 20px 20px;

  @media (max-width: 1023px) {
    margin-left: 0px;
  }

  & > header {
    border-bottom: 1px solid #ddd;
    position: fixed;
    top: 0px;
    left: 260px;
    right: 20px;
    padding: 10px 0px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f9f9f9;
    z-index: 9;

    .menu_button {
      display: none;
    }

    @media (max-width: 1023px) {
      left: 20px;

      .menu_button {
        display: flex;
      }
    }
  }
}

@primary-color: #4c72f1;