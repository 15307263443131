// 사이트 고정형 처리 ()
.windowLayout {
  width: 700px;
  min-width: 100%;
  section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

// 게시판 내 rowspan 구현
.rowspan {
  b {
    justify-content: space-between;
    position: relative;
    padding: 0;

    > span {
      display: flex;
      align-items: center;
      padding: 19px 8px;
      color: #000;
      height: 100%;
      border-right: 1px solid #e0e0e0;
    }

    em {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: space-around;
      height: 100%;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        width: 100%;
        height: 100%;

        &:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
        }
      }
    }
  }

  > div {
    > div {
      display: flex;
      align-items: center;
      padding: 0 20px;
      min-height: 40px;

      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }
}

// 게시판 하단 버튼 레이아웃
.buttonBox {
  max-width: 90vw;
  display: flex;
  flex: 1;
  margin-top: 20px;
  justify-content: flex-end;
  button {
    padding: 0 30px;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

// 게시판 이미지 업로드
.logoImgUpload {
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
  background-color: #fff;
}

// 게시판 주소 입력 폼
.addressForm {
  button {
    margin-bottom: 10px;
    height: 40px;
  }
  input:not(:last-child) {
    margin-right: 10px;
  }
}

// 게시판 운영시간 선택 폼
.selectTimeBox {
  li {
    display: flex;
    flex: 1;
    align-items: center;
    > div:not(:last-of-type),
    > span {
      margin-right: 10px;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

// 콘텐츠 간격 박스
.contentMargin {
  > *:not(:last-of-type) {
    margin-right: 10px;
  }
}

// 경고문
i {
  font-size: 14px;
  color: #f00;
  &:not(:first-child) {
    margin-left: 10px;
  }
  &.confirmText {
    color: #32cd32;
  }
}

.textRed {
  color: #f00;
}
.textGreen {
  color: #32cd32;
}
.textBlue {
  color: #4c72f1;
}

// 상품판매권한 신청 문구
.authTitle {
  display: block;
  padding-left: 50px;
  margin-bottom: 40px;
}

// 이미지 상호작용
.responable-image:hover::after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  content: '상세';
}

.dashboard_section {
  flex-direction: column;
  display: flex;
  //flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}



@primary-color: #4c72f1;