@import '~antd/dist/antd.less';
html,
body {
  font-family: 'Montserrat', 'NotoSansKR' !important;
  background: #f9f9f9;
  min-width: 1200px;
}

#root {
  height: 100%;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  font-size: 12px;
  font-weight: bold;
  padding: 5px 16px;
  padding-left: 48px;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 16px;
}

.ant-page-header {
  padding: 0px 0px 24px;
}

.ant-page-header-heading-sub-title {
  font-size: 11px;
}
.ant-page-header-heading-left {
  align-items: baseline;
}

.ant-table {
  font-size: 12px;
  background: transparent;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 4px 8px;
  min-height: 45px;
  height: 45px;
  vertical-align: middle;
}

.ant-table-thead > tr > th {
  background: #fff;
  height: 50px;
  min-height: 50px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #333;
  text-align: center !important;

  &:first-child {
    border-left: 1px solid #e0e0e0;
  }
  &:last-child {
    border-right: 1px solid #e0e0e0;
  }
}

.ant-form-horizontal .ant-form-item-label {
  & > label::after {
    display: none;
  }
}

.ant-row.ant-form-item > .ant-col.ant-form-item-label {
  min-width: 150px !important;
  text-align: left;
}

.ant-form-item {
  margin-bottom: 0px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px;
}

.ant-input-affix-wrapper {
  min-height: 36px;
}

.ant-picker {
  min-height: 36px;
  display: flex;
}

.ant-picker-range {
  display: flex;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
}

.list-style {
  list-style: none;
  padding: 0;
  li {
    padding: 0;
    height: 40px;
    display: flex;
    align-items: center;
    p {
      flex: 1;
      font-size: 14px;
      margin: 0;
    }
    span {
      font-size: 12px;
    }
  }
  li + li {
    border-top: 1px #eee solid;
  }
}
.ant-table-tbody > tr > td {
  text-align: center;
}
.ant-btn {
  height: 36px;
}
a {
  text-decoration: none;
  color: #000;
  &:hover,
  &:focus {
    text-decoration: none;
    color: #000;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  th {
    border-left: 1px solid #e0e0e0;
  }
}
.requireForm::after {
  content: '*';
  color: red;
}
a {
  color: @primary-color;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 50000;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-form-horizontal .ant-form-item-label {
  min-width: 100px !important;
  text-align: center;
}

.changed {
  color: @primary-color !important;
  .ant-select-selector {
    border-color: @primary-color !important;
    .ant-select-selection-item {
      color: @primary-color !important;
    }
  }
  &.ant-input {
    color: @primary-color !important;
    border-color: @primary-color !important;
  }
}

.descriptions-table-layout-fixed {
  table {
    table-layout: fixed !important;
  }
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: rgb(234, 232, 232);
}

.ant-table-wrapper {
  .ant-table {
    .ant-table-header {
      table {
        max-width: 100%;
        width: auto;
      }
    }
  }
}

.rdw-dropdown-selectedtext {
  width: 100px;
}

//상품정보, 상품문의 상세페이지 모달창 css
.se_component, .se-component{
  img{
    width: 100%;
    height: 100%;
  }
}
.ant-modal-confirm-content{
  margin-left: 0px !important;
}
[class*="ProductDetails_container"]{
  overflow-y: hidden;
}
@primary-color: #4c72f1;