.wrapper {
  width: 90%;
  max-width: 400px;

  &.finder {
    max-width: 500px;
  }

  h2 {
    color: #4c72f1;
    font-size: 34px;
    margin-bottom: 30px;
    letter-spacing: -0.1rem;
    text-align: center; //가운데정렬 추가
  }

  h4 {
    color: #34416b;
    font-size: 28px;
    margin-top: -25px;
    margin-bottom: 10px;
    letter-spacing: -0.1rem;
    text-align: center; //가운데정렬 추가
  }

  .input {
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    :global(.ant-input-prefix) {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 500;
      min-width: 100px;
      color: #999;
      letter-spacing: -0.01rem;
    }
  }

  .submit {
    margin-top: 15px;
    height: 60px;
  }
}

@primary-color: #4c72f1;