.wrapper {
  // background-color: red;
  display: flex;
  flex-direction: column;
}

.step_wrapper {
  margin: 50px auto 0;
}

.footer {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size:13px;

  .callCenterText {
    margin: 15px 0;
  }
}

@primary-color: #4c72f1;