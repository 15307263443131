.container {
  background-color: #fafafa;
  overflow-x: hidden;
  .sectionTitle {
    font-size: 16px;
    color: #333333;
    letter-spacing: -1.2px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .mainContainer {
    background-color: #ffffff;
    .thumbnailContainer {
      .thumbnail {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }
    .titleContainer {
      padding: 30px 20px;
      margin-bottom: 10px;
      .title {
        font-size: 23px;
        line-height: 30px;
        letter-spacing: -1.72px;
        color: #333333;
        font-weight: 500;
        margin-bottom: -5px;
        .best {
          color: #ffffff;
          font-size: 12px;
          letter-spacing: -0.9px;
          padding: 3px 7px;
          background-color: #f78031;
          vertical-align: middle;
          transform: translateY(-1515px);
        }
      }
      .ratingContainer {
        margin-top: 10px;
        display: flex;
        align-items: baseline;
        margin-bottom: 15px;
        .ratings {
          margin-right: 5px;
          .rating {
            width: 15px;
            height: 15px;
            &:not(:last-child) {
              margin-right: 1px;
            }
          }
        }
        .totalRating {
          font-size: 14px;
          color: #333333;
          letter-spacing: -1.05px;
          margin-right: 5px;
        }
        .reviewCount {
          font-size: 11px;
          color: #999999;
          letter-spacing: -0.82px;
        }
      }
      .priceContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .discountRate {
          padding: 2px 10px 1px;
          border-radius: 15px;
          font-size: 12px;
          color: #ffffff;
          background-color: #fa5d5d;
        }
        .price {
          flex: 1;
          text-align: right;
          font-size: 11px;
          color: #999999;
          letter-spacing: -0.82px;
          b {
            font-size: 24px;
            color: #333333;
            letter-spacing: -1.8px;
            margin-right: 5px;
          }
        }
      }
      .metaContainer {
        display: flex;
        align-items: center;
        .storeName {
          font-size: 14px;
          color: #999999;
          letter-spacing: -1.05px;
        }
      }
    }
  }
  .deliveryInfoContainer {
    background-color: #ffffff;
    padding: 30px 20px;
    .deliveryInfo {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .deliveryInfoTitle {
        font-size: 14px;
        color: #333333;
        line-height: 23px;
        letter-spacing: -1.05px;
        flex: 1;
      }
      .deliveryInfoDescription {
        font-size: 14px;
        color: #333333;
        line-height: 23px;
        letter-spacing: -1.05px;
        flex: 5;
      }
    }
  }
  .navigationContainer {
    padding: 0 20px;
    background-color: #fafafa;
    .navigation {
      display: inline-block;
      padding: 12px 0;
      font-size: 14px;
      color: #999999;
      letter-spacing: -1.05px;
      font-weight: 500;
      position: relative;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &.active {
        color: #333;
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: #4987fb;
        }
      }
      .count {
        color: #aaaaaa;
        background-color: #dddddd;
        padding: 2px 10px;
        border-radius: 10px;
        margin-left: 5px;
      }
    }
  }
  .contentsContainer {
    width: 100%;
    position: relative;
    padding-bottom: 20px;
    min-height: 400px;
    height: 400px;
    margin-bottom: 20px;
    background-color: #ffffff;
    &.active {
      height: auto;
    }
    .contents {
    }
    .moreButton {
      display: block;
      width: calc(100% - 40px);
      position: absolute;
      border: 0;
      background-color: #4987fb;
      padding: 15px 0;
      color: #ffffff;
      bottom: 20px;
      font-size: 18px;
      letter-spacing: -1.05px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .tagContainer {
    background-color: #ffffff;
    padding: 30px 20px;
    .tags {
      display: flex;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .tag {
        background-color: #4987fb;
        padding: 6px 15px;
        border-radius: 30px;
        color: #ffffff;
        font-size: 15px;
        letter-spacing: -0.75px;
        white-space: pre;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}

@primary-color: #4c72f1;