.wrapper {
  margin: 0 auto;
  margin-top: 100px;
  width: 100%;
}

.bottomBtnWrapper {
  margin-top: 30px;
  margin-left:-8px;
  margin-right:-8px;
}

.defaultBtn {
  width: auto;
  flex:1;
  margin: 0 8px;
}

@primary-color: #4c72f1;