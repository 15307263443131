.container {
  border-radius: 10px;
  background: #fff;
  min-width: 160px;
  padding: 15px;
  border: 1px #fafafa solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02);
  margin: 10px 3px;
  flex: 0;
  cursor: pointer;
  label {
    font-size: 12px;
    font-weight: 700;
    color: #999;
    text-align: left;
    display: block;
    margin-bottom: 20px;
  }
  p {
    font-size: 22px;
    font-family: 'Montserrat', 'NotoSansKR';
    font-weight: 600;
    text-align: right;
    margin: 0;
    color: @primary-color;
  }
}

@primary-color: #4c72f1;